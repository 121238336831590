import { getAbTestV2 } from 'dibs-buyer-layout/exports/clientABTestV2';

export const PERSONALIZED_RERANK_TEST_NAME = 'Personalized-Rerank' as const;
export const ITEM_PRICE_IN_SELLABILITY = 'ItemPriceInSellability' as const;
export const TRADE_QUICK_FILTERS = 'TradeQuickFilters' as const;
export const MORE_SPONSORED_ADS = 'MoreSponsoredAds' as const;
export const SHIFT_BUY_RECENT_SALES = 'ShiftBuyRecentSales' as const;
export const REORDERED_FILTER_MENU = 'ReorderedFilterMenu' as const;
export const URGENCY_SIGNALS_MW = 'CartUrgencySignalMWv2' as const;
export const ALL_URGENCY_SIGNALS_SB = 'AllUrgencySignalsSB' as const;

export const getPersonalizedRerankAbTestVariant = (): string | undefined =>
    getAbTestV2(PERSONALIZED_RERANK_TEST_NAME)?.variant;

export const getMoreSponsoredAdsAbTest = (): string | undefined =>
    getAbTestV2(MORE_SPONSORED_ADS)?.variant;
